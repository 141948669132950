// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  googleAPIKey: "AIzaSyCotansnYziIjg4pbIXLn4CjsvoIzZ3PBk",
  buyUrl : 'https://1.envato.market/6NV1b',
  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  baseURL: "https://test-api.elegantco.com.sa/restapi",
  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.ROLE_Admin,
  notificationsWebSocketApi:
    "https://test-api.elegantco.com.sa/getNotified",
 
};
